@tailwind base;
@tailwind utilities;


div.cards {
    @apply grid max-md:grid-cols-2 gap-4 grid-cols-3 max-sm:grid-cols-1;

    > div {
        @apply bg-red shadow  text-center p-5 box-border flex flex-col justify-start;

        > h3 {
            @apply h-16 max-xl:h-10 max-md:h-6;
            @apply !leading-none text-white m-0 max-[1280px]:text-[22px] max-[1080px]:text-[18px] max-[374px]:text-[16px];
        }
        
        > p {
            @apply !leading-none m-0 max-[374px]:text-[14px];
        }
    }
}