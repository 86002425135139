*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.\!bottom-\[-75px\] {
  bottom: -75px !important;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[10px\] {
  bottom: 10px;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-\[10px\] {
  right: 10px;
}

.top-1\/2 {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.\!mb-10 {
  margin-bottom: 2.5rem !important;
}

.\!mb-8 {
  margin-bottom: 2rem !important;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.h-\[204px\] {
  height: 204px;
}

.h-\[212px\] {
  height: 212px;
}

.h-\[372px\] {
  height: 372px;
}

.h-full {
  height: 100%;
}

.max-h-\[18px\] {
  max-height: 18px;
}

.max-h-\[24px\] {
  max-height: 24px;
}

.max-h-\[666px\] {
  max-height: 666px;
}

.w-\[282px\] {
  width: 282px;
}

.w-\[384px\] {
  width: 384px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[96px\] {
  width: 96px;
}

.w-full {
  width: 100%;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.grow {
  flex-grow: 1;
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.self-start {
  align-self: flex-start;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.border-0 {
  border-width: 0;
}

.border-\[20px\] {
  border-width: 20px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(248 238 226 / var(--tw-border-opacity, 1));
}

.bg-\[\#FAFAFA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f4806c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 128 108 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(35 35 35 / var(--tw-bg-opacity, 1));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(244 128 108 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(248 238 226 / var(--tw-bg-opacity, 1));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 191 87 / var(--tw-bg-opacity, 1));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-1\.5 {
  padding: .375rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-7 {
  padding: 1.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.\!pr-5 {
  padding-right: 1.25rem !important;
}

.pl-1 {
  padding-left: .25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-\[Arial\] {
  font-family: Arial;
}

.font-black {
  font-family: Gotham Black, sans-serif;
}

.font-light {
  font-family: Gotham Light, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[60px\] {
  font-size: 60px;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-\[90\%\] {
  line-height: 90%;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-\[\#FFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-\[\#f4806c\] {
  --tw-text-opacity: 1;
  color: rgb(244 128 108 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(35 35 35 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(248 238 226 / var(--tw-text-opacity, 1));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 191 87 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 6px 6px 0 #0000001a;
  --tw-shadow-colored: 6px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-yellow {
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 6px 6px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #ffbf57;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[-8px_-8px_0_rgba\(0\,0\,0\,0\.08\)\] {
  --tw-drop-shadow: drop-shadow(-8px -8px 0 #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[8px_8px_0_rgba\(0\,0\,0\,0\.08\)\] {
  --tw-drop-shadow: drop-shadow(8px 8px 0 #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

div.cards {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  display: grid;
}

@media not all and (width >= 768px) {
  div.cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media not all and (width >= 640px) {
  div.cards {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

div.cards {
  & > div {
    box-sizing: border-box;
    --tw-bg-opacity: 1;
    background-color: rgb(244 128 108 / var(--tw-bg-opacity, 1));
    text-align: center;
    --tw-shadow: 6px 6px 0 #0000001a;
    --tw-shadow-colored: 6px 6px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.25rem;
    display: flex;
  }

  & > div {
    & > h3 {
      height: 4rem;
    }

    @media not all and (width >= 1280px) {
      & > h3 {
        height: 2.5rem;
      }
    }

    @media not all and (width >= 768px) {
      & > h3 {
        height: 1.5rem;
      }
    }

    & > h3 {
      --tw-text-opacity: 1;
      color: rgb(248 238 226 / var(--tw-text-opacity, 1));
      margin: 0;
      line-height: 1 !important;
    }

    @media (width <= 1280px) {
      & > h3 {
        font-size: 22px;
      }
    }

    @media (width <= 1080px) {
      & > h3 {
        font-size: 18px;
      }
    }

    @media (width <= 374px) {
      & > h3 {
        font-size: 16px;
      }
    }

    & > p {
      margin: 0;
      line-height: 1 !important;
    }

    @media (width <= 374px) {
      & > p {
        font-size: 14px;
      }
    }
  }
}

@media (width <= 1280px) {
  .max-\[1280px\]\:left-\[-2\%\] {
    left: -2%;
  }

  .max-\[1280px\]\:right-\[-2\%\] {
    right: -2%;
  }

  .max-\[1280px\]\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .max-\[1280px\]\:h-\[240px\] {
    height: 240px;
  }

  .max-\[1280px\]\:w-\[340px\] {
    width: 340px;
  }

  .max-\[1280px\]\:w-full {
    width: 100%;
  }

  .max-\[1280px\]\:max-w-\[600px\] {
    max-width: 600px;
  }
}

@media (width <= 1160px) {
  .max-\[1160px\]\:\!hidden {
    display: none !important;
  }
}

@media (width <= 1080px) {
  .max-\[1080px\]\:top-10 {
    top: 2.5rem;
  }

  .max-\[1080px\]\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .max-\[1080px\]\:max-h-\[573px\] {
    max-height: 573px;
  }

  .max-\[1080px\]\:\!w-\[80\%\] {
    width: 80% !important;
  }

  .max-\[1080px\]\:w-\[280px\] {
    width: 280px;
  }

  .max-\[1080px\]\:w-\[76px\] {
    width: 76px;
  }

  .max-\[1080px\]\:whitespace-nowrap {
    white-space: nowrap;
  }

  .max-\[1080px\]\:text-\[14px\] {
    font-size: 14px;
  }
}

@media (width <= 1024px) {
  .max-\[1024px\]\:w-\[230px\] {
    width: 230px;
  }
}

@media (width <= 1023px) {
  .max-\[1023px\]\:\!left-\[-10px\] {
    left: -10px !important;
  }

  .max-\[1023px\]\:bottom-\[30\%\] {
    bottom: 30%;
  }

  .max-\[1023px\]\:top-0 {
    top: 0;
  }

  .max-\[1023px\]\:\!mb-0 {
    margin-bottom: 0 !important;
  }

  .max-\[1023px\]\:\!mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .max-\[1023px\]\:\!mt-0 {
    margin-top: 0 !important;
  }

  .max-\[1023px\]\:mb-0 {
    margin-bottom: 0;
  }

  .max-\[1023px\]\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .max-\[1023px\]\:ml-\[-200px\] {
    margin-left: -200px;
  }

  .max-\[1023px\]\:ml-\[200px\] {
    margin-left: 200px;
  }

  .max-\[1023px\]\:mr-4 {
    margin-right: 1rem;
  }

  .max-\[1023px\]\:mt-10 {
    margin-top: 2.5rem;
  }

  .max-\[1023px\]\:mt-2 {
    margin-top: .5rem;
  }

  .max-\[1023px\]\:mt-6 {
    margin-top: 1.5rem;
  }

  .max-\[1023px\]\:mt-8 {
    margin-top: 2rem;
  }

  .max-\[1023px\]\:box-border {
    box-sizing: border-box;
  }

  .max-\[1023px\]\:hidden {
    display: none;
  }

  .max-\[1023px\]\:\!h-auto {
    height: auto !important;
  }

  .max-\[1023px\]\:h-full {
    height: 100%;
  }

  .max-\[1023px\]\:\!w-\[100vw\] {
    width: 100vw !important;
  }

  .max-\[1023px\]\:\!w-\[52px\] {
    width: 52px !important;
  }

  .max-\[1023px\]\:\!w-\[96\%\] {
    width: 96% !important;
  }

  .max-\[1023px\]\:w-full {
    width: 100%;
  }

  .max-\[1023px\]\:\!flex-row {
    flex-direction: row !important;
  }

  .max-\[1023px\]\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .max-\[1023px\]\:items-center {
    align-items: center;
  }

  .max-\[1023px\]\:p-0 {
    padding: 0;
  }

  .max-\[1023px\]\:pt-4 {
    padding-top: 1rem;
  }

  .max-\[1023px\]\:text-center {
    text-align: center;
  }

  .max-\[1023px\]\:text-\[24px\] {
    font-size: 24px;
  }
}

@media (width <= 768px) {
  .max-\[768px\]\:\!m-0 {
    margin: 0 !important;
  }

  .max-\[768px\]\:w-\[260px\] {
    width: 260px;
  }
}

@media (width <= 767px) {
  .max-\[767px\]\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .max-\[767px\]\:justify-around {
    justify-content: space-around;
  }
}

@media (width <= 475px) {
  .max-\[475px\]\:\!mb-4 {
    margin-bottom: 1rem !important;
  }

  .max-\[475px\]\:ml-\[-20px\] {
    margin-left: -20px;
  }

  .max-\[475px\]\:ml-\[20px\] {
    margin-left: 20px;
  }

  .max-\[475px\]\:max-h-\[200px\] {
    max-height: 200px;
  }

  .max-\[475px\]\:scale-\[0\.75\] {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .max-\[475px\]\:\!flex-row {
    flex-direction: row !important;
  }

  .max-\[475px\]\:text-\[15px\] {
    font-size: 15px;
  }

  .max-\[475px\]\:text-\[16px\] {
    font-size: 16px;
  }

  .max-\[475px\]\:text-\[40px\] {
    font-size: 40px;
  }
}

@media (width <= 400px) {
  .max-\[400px\]\:scale-\[0\.84\] {
    --tw-scale-x: .84;
    --tw-scale-y: .84;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (width <= 374px) {
  .max-\[374px\]\:\!mt-0 {
    margin-top: 0 !important;
  }

  .max-\[374px\]\:\!px-\[10px\] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media (width >= 1080px) {
  .min-\[1080px\]\:shadow {
    --tw-shadow: 6px 6px 0 #0000001a;
    --tw-shadow-colored: 6px 6px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}
/*# sourceMappingURL=index.de0cca5a.css.map */
